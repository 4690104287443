import {
	Typography,
	Grid,
	Box,
	Stack,
	Paper,
	LinearProgress
} from "@mui/material";

import {
	ScoreLineChart,
	WatchlistSnapshot,
	MarketSnapshot,
	NewsTable
} from "../components"

function HomePage (props) {    
  
  const markets = JSON.parse(localStorage.getItem('markets'))
  
  return (
    <Box>
    <Typography variant={"h3"}
    	align="center"
    	sx={{ mt: 3, mb: 3}}
    	>Data at the speed of life.</Typography>
    <Grid container 
    	spacing={2}
			>
			<Grid item
				xs={12}
				sm={12}
				lg={3}
				>
				
			<WatchlistSnapshot />
			<MarketSnapshot />
				
      </Grid>
      <Grid item
				xs={12}
				sm={12}
				lg={6}
				>
				<ScoreLineChart 
					portfolios={{
						values: markets,
						visible: true
					}}
					searchable={true}
					/>
			<br/>
      <NewsTable  
					portfolios={{
						values: markets,
						visible: true
					}}
					defaultItem={{market: markets[0]}}
					searchable={true}
					/>
			</Grid>
      <Grid item
				xs={12}
				sm={12}
				lg={3}
				>
			</Grid>
  	</Grid>
    </Box>
  );

}


export default HomePage
