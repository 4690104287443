import { useState, useRef, useEffect } from "react";
import { Paper,
	InputBase, 
	IconButton,
	Popover
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { InstantSearch, 
	Configure,
	useInfiniteHits,
	useInstantSearch,
	useSearchBox
} from "react-instantsearch-hooks-web";

import Hit from "./Hit";


const typesenseApiKey = process.env.REACT_APP_TYPESENSE_API_KEY;

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: typesenseApiKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: "aidatame.com",
        path: "/typesense",
        protocol: "https"
      }
    ]
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    query_by: "issuer_ticker,name",
  }
});


function NoResultsBoundary({ children, anchorEl, ...props }) {
	const { indexUiState, results } = useInstantSearch();
  const [open, setOpen] = useState(Boolean(anchorEl))

	if (!indexUiState.query) {
		return <></>
	} else if (!results.__isArtificial && results.nbHits === 0) {
		return (
		  	<Popover open={open}
		  		onClose={() => setOpen(false)}
		  		disableAutoFocus={true}
			 		disableEnforceFocus={true}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
		  		>
				No results.
			</Popover>
		);
	} else {
		return children;
	}
}


function CustomInfiniteHits({onHitClick, anchorEl, mapFunction = (h) => { return h }, ...props}) {
  const {
    hits,
    currentPageHits,
    results,
    isFirstPage,
    isLastPage,
    showPrevious,
    showMore,
    sendEvent
  } = useInfiniteHits(props);
  
  const [open, setOpen] = useState(Boolean(anchorEl))

  return (
  	<Popover open={open}
  		onClose={() => setOpen(false)}
  		disableAutoFocus={true}
 			disableEnforceFocus={true}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
  		>
		{hits
		.map((h) => mapFunction(h))
		.map(h  => (
			<Hit hit={h} onClick={(h) => {onHitClick(h);setOpen(false)}} key={h} {...props}/>
		))}
	</Popover>
  );
}

function CustomSearchBox({toggleHits, ...props}) {
	const { query, refine } = useSearchBox(props);
	const [inputValue, setInputValue] = useState(query);
    
	const inputRef = useRef(null);
	
	const setQuery = (newQuery:string) => {
		setInputValue(newQuery);
		refine(newQuery);
	}
	
	if (query !== inputValue && inputRef.current?.isFocused) {
		setInputValue(query);
	}
    return (
    	
		<>
				
		<IconButton type="button" sx={{ p: '10px' }} aria-label="search">
		<SearchIcon />
		</IconButton>
		
		<InputBase
			ref={inputRef}
			placeholder={"Seach"}
			value={inputValue}
			onChange={(e) => {
				setQuery(e.currentTarget.value);
				toggleHits(e.currentTarget)
			}}
			/>
					
		</>
		
    );
}

export default function Search({onHitClick, filters, ...props}) {
	
  const [anchorEl, setAnchorEl] = useState(null);	

  return (
  	
	<Paper component="form"
		sx={{ 
			opacity: 0.3,
			display: "inline-block",
			minWidth: 300
		}}
		{...props}
		>
		<InstantSearch 
			indexName="info" 
			searchClient={typesenseInstantsearchAdapter.searchClient}
			>
			 <Configure
					analytics={false}
					filters={filters}
					/>
			<CustomSearchBox 
				toggleHits={setAnchorEl}
				{...props}
				/>
			<NoResultsBoundary 
			    anchorEl={anchorEl}
			    >
				<CustomInfiniteHits  
					showPrevious={false}
					style={{ overflowY: "scroll", 
						height: "250pt",
						minWidth: "350px",
					}}		
			    anchorEl={anchorEl}
			    onHitClick={onHitClick}
			    {...props}
					/>
			</NoResultsBoundary>
			
		</InstantSearch>
	</Paper>
	
  );

}

