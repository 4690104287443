import { Line } from "react-chartjs-2";
import { useState, useEffect } from "react";
import { 
    Checkbox,
    FormControlLabel,
    Stack
} from "@mui/material";
import { convert } from "./Converter";

export default function LineChart({items, index, interval, onClick, ...props}) {
    
  const [cumul, setCumul] = useState(true);
	const [data, setData] = useState({labels: [], datasets: []});
		
	const prepareData = (items, cumul, index) => {
		
		const datasets = []
		
    for (var i in items) {
    	
	    var values = []        
	    var labels = []
    	
    	for (var j in items[i][index]) {
    		values.push(items[i][index][j])
    		labels.push(j)
    	}
    	
    	if (cumul) {
    		values[0] = 0
    		var k = 1
    		while (k<values.length) {
    			values[k] = values[k-1] + values[k]
    			k++
    		}
    	}
    	
    	datasets.push({
	            label: i, 
	            data: values, 
	            fill: false, 
	            pointRadius: 0,
	            borderWidth: 2
	        })
    	
    }
	    
    setData({
    	labels: labels,
    	datasets: datasets
    })
	    
        
    }
    
    
    useEffect(() => {
    	prepareData(items, cumul, index)
    }, [items, index, interval])
        
    const options = {
      plugins: {
      	colors: {
      		forceOverride: true
      	},
        legend:{
          labels : {
            usePointStyle: true,
            boxWidth: 6
          },
        },
        tooltip: {
          enabled: true,
          intersect: false,
          interaction: {
          	mode: "index",
          	axis: "x"
          },
          callbacks: {
            label: function(tooltipItem) {
              return [tooltipItem.dataset.label, tooltipItem.raw.toLocaleString()];
            },
            title: function(tooltipItem) {
              return convert(tooltipItem[0].label, interval)
            }
          }
        }
      },
      scales: {
        x: {
          ticks: {
            maxTicksLimit: 5,
            maxRotation: 0,
            minRotation: 0,
            callback: function(value, index, values) {
              return convert(this.getLabelForValue(value), interval)
            }
          },
          grid: {
            display: false,
            borderColor: '#adb5bd'
          },

        },
        y: {
          grid: {
            display: false,
            borderColor: '#adb5bd'
          }
        }
      },
      
	    /*onClick: (e) => {
	    	
	      const x = e.chart.scales.x.getValueForPixel(e.x);
	      const y = e.chart.scales.y.getValueForPixel(e.y);
	    	onClick(e.chart.scales.x.getLabelForValue(x), data.datasets[e.chart.id - 1].label)
	    },
	    
	    interaction: {
	        mode: 'dataset',
	        intersect: true
	    }*/
		    
      

    }
    
    return (
        
      <div>
      <FormControlLabel 
      	label={"Cumulative"}
      	control={<Checkbox defaultChecked 
      				checked={cumul}
              onChange={(e) => {
              	setCumul(e.target.checked); 
              	prepareData(items, e.target.checked, index)
            	}}
      				/>
      	}
      	/> 
      <Line options={options} data={data}/>
      
      </div>
    );

}
