import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import {
	HomePage,
	TickerDetail,
	LoginPage,
	ResetPassword,
	SignUpSuccess,
	AccountSettingPage,
	AdminPage
} from "./views";

import Main from "./views/Main";
import { post, get } from "./components/Functions";

import Cookies from "js-cookie";
import { useState, useEffect, useRef, Suspense } from "react";

import { CssBaseline, 
	Box,
	LinearProgress
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ThemeContext } from "./contexts";

import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Colors } from 'chart.js';


import { darkMode, lightMode } from "./themes/Themes";

Chart.register(Colors);
Chart.register(CategoryScale);


function PrivateRoute({ children, ...props}) {

  const user = JSON.parse(localStorage.getItem("user"))//[user, setUser] = useState(JSON.parse(localStorage.getItem("user")))
  
  if (!user) {
  	 
  	return <Navigate to="/login" replace={true} />
  }
  return <Outlet />
}

function App(props) {
	const [theme, setTheme] = useState(false);
	const ref = useRef(null);
	
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true)
  
  const [user, setUser] = useState(null)
  
  useEffect(() => {
  	
		localStorage.removeItem("user")
		get("/score/api/v2/markets")
		.then(res => {
			if (!res.isError) {
			
				localStorage.setItem('markets', JSON.stringify(res.data))
				setIsLoading(false)
			} else {
			
				localStorage.setItem('markets', JSON.stringify([]))
				setIsLoading(false)
			}
		})
		
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
		if (token) {
			post("/user/api/v2/user/auth", {token: token})
			.then(res => {
				if (!res.isError) {
					
					let user = res.data;
					delete user["password"]
					localStorage.setItem("user", JSON.stringify(user))
					setUser(user)
					setError(null)
				} else {
					setError(res.error)
				}
			})
		}
		
		
	}, [])
	
	if (isLoading) {
		return <LinearProgress />
	} else {
    
    
		return (

	    <ThemeContext.Provider value={{ theme, setTheme }}>
			<ThemeProvider theme={theme ? darkMode : lightMode}>
			<Box sx={{ pb: 7 }} ref={ref}>
			
			<CssBaseline />
			
			<Suspense fallback={<LinearProgress />}>
				<BrowserRouter>
        <Routes>
        	<Route element={<PrivateRoute user={user} />}>
        	
          <Route exact path="/" 
          	element={<Main content={<HomePage theme={theme}/>} 
												setTheme={setTheme} 
												theme={theme}
				  							/>
      			}
      		/>
    		<Route path="/:market/:ticker" 
          	element={<Main content={<TickerDetail theme={theme}/>} 
												setTheme={setTheme} 
												theme={theme}
				  							/>
    			}
      		/>
    		<Route path="/account" 
          	element={<Main content={<AccountSettingPage theme={theme}/>} 
												setTheme={setTheme} 
												theme={theme}
				  							/>
      			}
      		/>
    		<Route path="/admin" 
          	element={<Main content={<AdminPage theme={theme}/>} 
												setTheme={setTheme} 
												theme={theme}
				  							/>
      			}
      		/>
      	</Route>
        		
      		
				<Route path="/login" element={<LoginPage isMember={true}/>} />
				<Route path="/sign-up" element={<LoginPage isMember={false}/>} />
				<Route path="/signup-success" element={<SignUpSuccess />} />
				<Route path="/password/:email" element={<ResetPassword />} />
            
        </Routes>
		    </BrowserRouter>
			</Suspense>
			
			</Box>
			</ThemeProvider>
			</ThemeContext.Provider>

		);
	}
    
}

export default App;
