import { useState, useEffect } from 'react'
import { 
    LinearProgress,
    Box,
    Stack,
    Menu
} from "@mui/material"
import { get } from "./Functions"
import News from "./Table/News"
import Cookies from "js-cookie";
import {
	CardActions,
	Search
} from "../components"

export default function NewsTable({
		portfolios,
		defaultItem,
		searchable,
		...props}) {
    
	const oneHourToMilliSeconds = 360000;
	
	const [items, setItems] = useState([]);
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	
	
	const [portfolio, setPortfolio] = useState(portfolios.values[0]);
	const [start, setStart] = useState(null);
	const [finish, setFinish] = useState(null); 
	const [range, setRange] = useState(null);
	const [interval, setInterval] = useState(null);
	
	const handleGetData = (interval, start, finish, range, item=null) => {
		setIsLoaded(false)
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
		
		get("/news/api/v1/news/" 
			+ item.market 
			+ (item.issuer_ticker ? "?ticker=" + item.issuer_ticker : "")
			+ (item.issuer_ticker ? "&" : "?") +  "start=" + start.toISOString() + "&finish=" + finish.toISOString(),
			token
		).then(res => {
			if (!res.isError) {
				setItems(res.data)
				setIsLoaded(true)
				setStart(start)
				setFinish(finish)
			} else {
				setError(res.error)
			}
		})
	}
	
  const handlePortfolioChange = (v,i) => {
  	setPortfolio(v)
  	handleGetData(interval, start, finish, range, {market : v} )
  	
  }
	
	
	useEffect(() => {
    const now = new Date()
    const finish = new Date(now.getTime() - now.getTimezoneOffset() * oneHourToMilliSeconds)
    var start = new Date(now.getTime() - now.getTimezoneOffset() * oneHourToMilliSeconds)
    start.setMonth(start.getMonth() - 3)
    
		handleGetData("1D", start, finish, "3M", defaultItem)
	}, [defaultItem])
  
	return (
	    <Box >
	    <CardActions 
		  	options={{
		  		score: { fallback: "RECENT NEWS", visible: false, values: ["RECENT NEWS"]},
		  		portfolios: portfolios,
		  	}}
		  	onTimeChange={handleGetData}
		  	onPortfolioChange={handlePortfolioChange}
		  	subActions={searchable
				  ? <Search onHitClick={(h) => handleGetData(interval, start, finish, range, h ) } 
				  	filters={"market:" + portfolio}
				  	sx={{ borderRadius: 0 }}
				  	/>   	
			  	: <></>
				}
		  	/>
    	{error
    		? <>{error.message}</>
  			: !isLoaded
  				? <LinearProgress />
  				: <News items={items} />
    	}
      </Box>
	)

}


