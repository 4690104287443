import {
	ScoreTable
} from "../components"
import { useState, useEffect } from "react"
import { get, put, post } from "../components/Functions"
import Cookies from "js-cookie";

function MarketSnapshot(props) {
  const markets = JSON.parse(localStorage.getItem('markets'))
  
  const fetchMarket = (i,s,f,r,p) => {
		
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
		let res = get("/score/api/v2/score/" 
			+ p
			+ "?interval=" + i
			+ "&start=" + s.toISOString()
			+ "&finish=" + f.toISOString(),
			token
		).then(res => {
			if (!res.isError) {
				res.data = res.data.flat()
				return {...res}
			} else {
				return {...res}
			}
		})
		
		return res
	}
  
  return (
		<ScoreTable 
				onFetch={fetchMarket}
				portfolios={{
					values: markets,
					visible: true
				}}
				defaultPortfolio={markets[0]}
				/>
	)
}


export default MarketSnapshot;
